import { useState, useEffect } from 'react';
import AOS from 'aos';
import useApiService from '../../services/ApiService_';

import BannerBlock from '../bannerBlock/BannerBlock';
import AboutBlock from '../aboutBlock/AboutBlock';
import StadiumsBlock from '../stadiumBlock/StadiumBlock';
import HotelsBlock from '../hotelsBlock/HotelsBlock';
import CultureBlock from '../cultureBlock/CultureBlock';
import AttractionsBlock from '../attractionsBlock/AttractionsBlock';
import EntertainmentBlock from '../entertainmentBlock/EntertainmentBlock';
import NationalBlock from '../nationalBlock/NationalBlock';

import AboutModal from '../modals/AboutModal';
import VideoModal from '../modals/VideoModal';

import './contentBlock.scss';
import 'aos/dist/aos.css';

AOS.init();

const ContentBlock = () => {
    const {
        getAboutBlock,
        getStadiumsBlock,
        getHotelsBlock,
        getCultureBlock,
        getAttractionsBlock,
        getEntertaimentBlock,
        getNationalBlock
    } = useApiService();

    const [about, setAbout] = useState(null);
    const [stadium, setStadium] = useState(null);
    const [hotels, setHotels] = useState(null);
    const [culture, setCulture] = useState(null);
    const [attractions, setAttractions] = useState(null);
    const [entertainment, setEntertaiment] = useState(null);
    const [national, setNational] = useState(null);

    const [videoSrc, setVideoSrc] = useState(null);
    const [aboutModal, setAboutModal] = useState(false);
    const [videoModal, setVideoModal] = useState(false);

    const toggleAboutModal = () => {
        setAboutModal(aboutModal => !aboutModal);
    }
    const toggleVideoModal = (blockInfo) => {
        setVideoSrc(blockInfo)
        setVideoModal(videoModal => !videoModal);
    }

    useEffect(() => {
        getAboutBlock()
            .then(setAbout);
        getStadiumsBlock()
            .then(setStadium)
        getHotelsBlock()
            .then(setHotels)
        getCultureBlock()
            .then(setCulture)
        getAttractionsBlock()
            .then(setAttractions)
        getEntertaimentBlock()
            .then(setEntertaiment)
        getNationalBlock()
            .then(setNational)
    }, [])

    return (
        <>
            <BannerBlock about={about} toggleVideoModal={toggleVideoModal}/>
            <div className="contentBlock">
                <div className="container">

                    {about ? <AboutBlock about={about} toggleAboutModal={toggleAboutModal} toggleVideoModal={toggleVideoModal}/> : null}
                   
                    {attractions ? <AttractionsBlock attractions={attractions} toggleVideoModal={toggleVideoModal}/> : null}

                    {culture ? <CultureBlock culture={culture} toggleVideoModal={toggleVideoModal}/> : null}

                    {stadium ? <StadiumsBlock stadium={stadium} toggleVideoModal={toggleVideoModal}/> : null}

                    {hotels ? <HotelsBlock hotels={hotels} toggleVideoModal={toggleVideoModal}/> : null}

                    {entertainment ? <EntertainmentBlock entertainment={entertainment} toggleVideoModal={toggleVideoModal}/> : null}

                    {national ? <NationalBlock national={national} toggleVideoModal={toggleVideoModal}/> : null}

                    {aboutModal ? <AboutModal toggleOpen={aboutModal} toggleAboutModal={toggleAboutModal}/> : null}
                    {videoSrc ? <VideoModal videoSrc={videoSrc} toggleOpen={videoModal} toggleVideoModal={toggleVideoModal}/> : null}
                </div>
            </div>
        </>
    )
}

export default ContentBlock;