import useHttp from "../hooks/http.hook";

const useApiService = () => {
    const { request } = useHttp();
    
    const _apiBase = "https://api.alga2027.kz/api/content/item/";
    const _apiKey = "api_key=API-5b00b39fee2e21b2f85302cda563a27ab11382b9";

    const getHeaderMenu = async () => {  
        return request(`${_apiBase}header?${_apiKey}`);
    }

    const getAboutBlock = async () => {  
        return request(`${_apiBase}about?${_apiKey}`);
    }

    const getAboutModal = async () => {  
        return request(`${_apiBase}about_modal?${_apiKey}`);
    }

    const getStadiumsBlock = async () => {  
        return request(`${_apiBase}stadiums?${_apiKey}`);
    }

    const getHotelsBlock = async () => {  
        return request(`${_apiBase}hotels?${_apiKey}`);
    }

    const getCultureBlock = async () => {  
        return request(`${_apiBase}culture?${_apiKey}`);
    }

    const getAttractionsBlock = async () => {  
        return request(`${_apiBase}attractions?${_apiKey}`);
    }

    const getEntertaimentBlock = async () => {  
        return request(`${_apiBase}entertainment?${_apiKey}`);
    }

    const getNationalBlock = async () => {  
        return request(`${_apiBase}national?${_apiKey}`);
    }

    const getFooter = async () => {  
        return request(`${_apiBase}footer?${_apiKey}`);
    }

    return {
        getHeaderMenu,
        getAboutBlock,
        getAboutModal,
        getStadiumsBlock,
        getHotelsBlock,
        getCultureBlock,
        getAttractionsBlock,
        getEntertaimentBlock,
        getNationalBlock,
        getFooter,
    }
}

export default useApiService;