import { useState, useEffect } from 'react';
import useApiService from '../../services/ApiService_';

import flag from '../../resources/img/flag.jpg'

import "./modals.scss";

const AboutModal = (props) => {

  const {getAboutModal} = useApiService();
  const [aboutModal, setAboutModal] = useState({});
  useEffect(() => {
    getAboutModal()
          .then(onInfoLoaded);
  }, [])
  const onInfoLoaded = (info) => {
    setAboutModal(info);
  };


  return (
    <>
      <div className={props.toggleOpen ? "modal modal--active" : "modal"} onClick={props.toggleAboutModal}></div>
      <div className={props.toggleOpen ? "modal__body modal--about modal--active" : "modal__body modal--about"}>
        <div className="modal__close" onClick={props.toggleAboutModal}>
          <i className="fa-regular fa-circle-xmark"></i>
        </div>
        <div className="modal__content">
          <div className="modal__header">
            <div className="d-flex">
              <div className="modal__image">
                <img src={flag} alt="" />
              </div>
              <div className="modal__title">
                {aboutModal.title}
              </div>
            </div>
          </div>
          <p className='modal__text'>
            {aboutModal.description}
          </p>
          <p className='modal__text'>
            {aboutModal.description_0}
          </p>
          <p className='modal__text'>
            {aboutModal.description_01}
          </p>
          <div className="modal__row">
            <div className="modal__col">
              <h3 className="modal__title modal__title-text">
                {aboutModal.title_1}
              </h3>
              <p className="modal__text">
                {aboutModal.description_1}
              </p>
            </div>
            <div className="modal__col">
              <h3 className="modal__title modal__title-text">
                {aboutModal.title_2}
              </h3>
              <p className="modal__text">
                {aboutModal.description_2}
              </p>
            </div>
            <div className="modal__col">
              <h3 className="modal__title modal__title-text">
                {aboutModal.title_3}
              </h3>
              <p className="modal__text">
                {aboutModal.description_3}
              </p>
            </div>
            <div className="modal__col">
              <h3 className="modal__title modal__title-text">
                {aboutModal.title_4}
              </h3>
              <p className="modal__text">
                {aboutModal.description_4}
              </p>
            </div>
            <div className="modal__col">
              <h3 className="modal__title modal__title-text">
                {aboutModal.title_5}
              </h3>
              <p className="modal__text">
                {aboutModal.description_5}
              </p>
            </div>
            <div className="modal__col">
              <h3 className="modal__title modal__title-text">
                {aboutModal.title_6}
              </h3>
              <p className="modal__text">
                {aboutModal.description_6}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutModal;