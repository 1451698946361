import { useCallback } from "react";

const useHttp = () => {
  const request = useCallback(async (url, method = 'GET', body = null, header = {'Content-Type' : 'application/json'}) => {
    try {
        const response = await fetch(url, {method, body, header});
        if (!response.ok) {
            throw new Error(`Не выполнилась загрузка с: ${url}, статус ошибки: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch(e) {
        throw e;
    }
  }, []);
  return {request};
}

export default useHttp;