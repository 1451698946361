import './aboutBlock.scss';

const AboutBlock = ({about, toggleAboutModal, toggleVideoModal}) => {

    return (
        <section id='about' className="aboutBlock" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
            <div className="row">
                <div className="section__image">
                    <div className='title title__mobile'>{about.title}</div>
                    <img src={`https://api.alga2027.kz/storage/uploads/` + about.image.path} alt={about.title} />
                </div>
                <div className="section__text">
                    <h1 className='title'>{about.title}</h1>
                    <p className='text'>{about.description}</p>
                    <div className="button__wrap">
                        <div className="button__flex">
                            {
                                about.button_1_switch ?   
                                <div className="button button__blue" onClick={toggleAboutModal}>
                                    {about.button_1}
                                </div>
                                : null 
                            }
                        </div>
                        <div className="button__flex">
                            {
                                about.button_2_switch ?
                                    <div className="button button__yellow" onClick={() => toggleVideoModal(about.youtube_link)}>
                                        <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="13.5001" cy="13.0001" r="12.9999" transform="rotate(-180 13.5001 13.0001)" fill="white" fillOpacity="0.4"/>
                                            <path d="M18.6982 12.9035L11.4965 8.70244L11.4965 17.1045L18.6982 12.9035Z" fill="white"/>
                                        </svg>
                                        {about.button_2}
                                    </div>
                                : null 
                            }
                            {
                                about.button_3_switch ?
                                    <div className="button button__yellow" onClick={() => toggleVideoModal(about.youtube_link_2)}>
                                        <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="13.5001" cy="13.0001" r="12.9999" transform="rotate(-180 13.5001 13.0001)" fill="white" fillOpacity="0.4"/>
                                            <path d="M18.6982 12.9035L11.4965 8.70244L11.4965 17.1045L18.6982 12.9035Z" fill="white"/>
                                        </svg>
                                        {about.button_3}
                                    </div>
                                : null 
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutBlock;