import "./modals.scss";

const VideoModal = ({toggleOpen, toggleVideoModal, videoSrc}) => {
  return (
    <>
      <div className={toggleOpen ? "modal modal--active" : "modal"} onClick={() => toggleVideoModal(null)}></div>
      <div className={toggleOpen ? "modal__body modal--video modal--active" : "modal__body modal--video"}>
        <div className="modal__close" onClick={() => toggleVideoModal(null)}>
          <i className="fa-regular fa-circle-xmark"></i>
        </div>
        <iframe src={videoSrc + '?autoplay=1'} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>
    </>
  );
};

export default VideoModal;