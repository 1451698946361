import { useState, useEffect } from 'react';
import useApiService from '../../services/ApiService_';

import logo from '../../resources/img/logo.svg';
import logoMobile from '../../resources/img/menu__mobile.svg';

import './appHeader.scss';

const AppHeader = () => {
    const {getHeaderMenu, getFooter} = useApiService();

    const [menu, setMenu] = useState({});
    const [footer, setFooter] = useState({});
    const [mobileMenu, setMobileMenu] = useState(false)

    useEffect(() => {
        getHeaderMenu()
            .then(setMenu);
        getFooter()
            .then(setFooter);
    }, [])

    const openMobileMenu = () => {
        setMobileMenu(mobileMenu => !mobileMenu);
    }

    return (
        <header>
            <div className="container">
                <div className="header">
                    <div className="header__menu">
                        <ul>
                            {/* <li><a href="#about">{menu.text_1}</a></li> */}
                            <li><a href="#attractions">{menu.text_6}</a></li>
                            <li><a href="#culture">{menu.text_5}</a></li>
                            <li><a href="#stadiums">{menu.text_3}</a></li>
                            <li><a href="#hotels">{menu.text_4}</a></li>
                        </ul>
                    </div>
                    <div className="header__logo">
                        <div className="logo">
                            <a href="/">
                                <img src={logo} alt="logo" />
                            </a>
                        </div>
                    </div>
                    <div className="header__menu">
                        <ul>
                            <li><a href="#entertainment">{menu.text_7}</a></li>
                            <li><a href="#national">{menu.text_8}</a></li>
                            <li><a href="#application"></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={mobileMenu ? "header-mobile header-mobile--active" : "header-mobile"}>
                <ul className="header-mobile__menu" onClick={openMobileMenu}>
                    <li><a href="#bannerBlock">Home</a></li>
                    <li><a href="#attractions">{menu.text_6}</a></li>
                    <li><a href="#culture">{menu.text_5}</a></li>
                    <li><a href="#stadiums">{menu.text_3}</a></li>
                    <li><a href="#hotels">{menu.text_4}</a></li>
                    <li><a href="#entertainment">{menu.text_7}</a></li>
                    <li><a href="#national">{menu.text_8}</a></li>
                </ul>
                <div className="header-mobile__footer">
                    <div className="header-mobile__logo">
                        <img src={logoMobile} alt="" />
                    </div>
                    <p className="header-mobile__text">
                        {footer.text_1} {footer.text_2}
                    </p>
                    <p className="header-mobile__text" dangerouslySetInnerHTML={{__html: `${footer.text_3} ${footer.text_4}`}}>
                    </p>
                </div>
                <div className='header-mobile__close' onClick={openMobileMenu}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="1.48499" width="2.10001" height="25" transform="rotate(-45 0 1.48499)" fill="white"/>
                        <rect width="2.10001" height="25" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 19.1631 1.48499)" fill="white"/>
                    </svg>
                </div>
            </div>
            <div className="hamburger" onClick={openMobileMenu}>
                <div className='bars'>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>
                <p>Menu</p>
            </div>
        </header>
    )
}

export default AppHeader;