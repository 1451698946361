import AppHeader from '../appHeader/AppHeader';
import AppFooter from '../appFooter/AppFooter';
import ContentBlock from '../contentBlock/ContentBlock';

const App = () => {
    return (
        <div className="app">
            <AppHeader />
            <main>
                <ContentBlock />
            </main>
            <AppFooter />
        </div>
    )
}

export default App;