const HotelsBlock = ({hotels, toggleVideoModal}) => {

    return (
        <section id='hotels' className='section section__bg' data-aos="fade-left" data-aos-duration="1000" data-aos-easing="ease-in">
            <div className="row">
                <div className="section__image">
                    <div className='title title__mobile'>{hotels.title}</div>
                    <div className="section__image-block" onClick={() => toggleVideoModal(hotels.youtube_link)}>
                        <img src={`https://api.alga2027.kz/storage/uploads/` + hotels.image.path} alt={hotels.title} />
                        <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="41.5108" cy="41.5108" r="41.5108" transform="matrix(-1 0 0 1 83.0215 0)" fill="black" fillOpacity="0.4"/>
                            <path d="M58.1082 41.819L35.1118 55.2335L35.1118 28.4045L58.1082 41.819Z" fill="white"/>
                        </svg>
                    </div>
                </div>
                <div className="section__text">
                    <h2 className="title">{hotels.title}</h2>
                    <p className="text">
                        {hotels.text_1}
                    </p>
                    <p className="text">
                        {hotels.text_2}
                    </p>
                    <p className="text">
                        {hotels.text_3}
                    </p>
                    <p className="text">
                        {hotels.text_4}
                    </p>
                    <p className="text">
                        {hotels.text_5}
                    </p>
                </div>
            </div>
        </section>
    )
}

export default HotelsBlock;