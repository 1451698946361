import './bannerBlock.scss';

const BannerBlock = ({about, toggleVideoModal}) => {
    return (
        <section id="bannerBlock" className="bannerBlock">
            {
            about ?
                <>
                {
                    about.button_2_switch ?  
                    <div className="button button__yellow" onClick={() => toggleVideoModal(about.youtube_link)}>
                        <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="13.5001" cy="13.0001" r="12.9999" transform="rotate(-180 13.5001 13.0001)" fill="white" fillOpacity="0.4"/>
                            <path d="M18.6982 12.9035L11.4965 8.70244L11.4965 17.1045L18.6982 12.9035Z" fill="white"/>
                        </svg>
                        {about.button_2}
                    </div>
                    : null
                }
                {
                    about.button_3_switch ?  
                    <div className="button button__yellow" onClick={() => toggleVideoModal(about.youtube_link_2)}>
                        <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="13.5001" cy="13.0001" r="12.9999" transform="rotate(-180 13.5001 13.0001)" fill="white" fillOpacity="0.4"/>
                            <path d="M18.6982 12.9035L11.4965 8.70244L11.4965 17.1045L18.6982 12.9035Z" fill="white"/>
                        </svg>
                        {about.button_3}
                    </div>
                    : null
                }
                </>
            : null
            }
        </section>
    )
}

export default BannerBlock;